import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';

/* Services */

import { AuthService } from '../../core/services/auth/auth.service';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.css'],
  preserveWhitespaces: false
})

export class NavBarComponent implements OnInit {
  exibir: boolean = false;

  menuInterno: boolean = true;

  /* Verbos */

  verbosPedidosOrcamentos: string = "Pedidos";

  /* Storage */

  b2b_usa_como_orcamento: boolean = (localStorage.getItem("b2b_usa_como_orcamento") == "true");
  b2b_cor_primaria: string = localStorage.getItem("b2b_cor_primaria");

  name: string = "";

  constructor(
    private router: Router,
    private domSanitizer: DomSanitizer,
    private authService: AuthService
  ) { }

  ngOnInit() {
    if (this.b2b_usa_como_orcamento) {
      this.verbosPedidosOrcamentos = "Orçamentos";
    }

    this.authService.exibirNavBarEmitter.subscribe(
      exibir => {
        this.exibir = exibir;

        if (this.exibir) {
          this.name = localStorage.getItem("name");
        }

        this.b2b_cor_primaria = localStorage.getItem("b2b_cor_primaria");
      }
    );
  }

  alternarMenuInterno() {
    this.menuInterno = !this.menuInterno;
  }

  atualizar() {
    this.router.navigateByUrl("/not-found", { skipLocationChange: true }).then(() => {
      this.router.navigate(["/produtos"]);
    });
  }

  sair() {
    this.authService.desautorizar();
  }
}
