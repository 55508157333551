import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

import { Configuracao } from '../../models/configuracoes/configuracao';
import { ConfiguracaoBanner } from '../../models/configuracoes/configuracao-banner';

@Injectable({
  providedIn: 'root'
})

export class ConfiguracaoService {
  private baseUrl: string;
  private headers = new HttpHeaders();

  constructor(
    private http: HttpClient,
    @Inject("BASE_URL") baseUrl: string,
    private toastr: ToastrService
  ) {
    this.baseUrl = baseUrl;
  }

  public getByGuidClienteNapis(): Observable<Configuracao> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "guidclientenapis": localStorage.getItem("guidclientenapis")
    };

    return this.http.post<Configuracao>(this.baseUrl + "api/Configuracao/GetByGuidClienteNapis", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of(null);
      })
    );
  }

  /* Configuração Banner */

  public getConfiguracaoBannerByGuidClienteNapis(): Observable<ConfiguracaoBanner[]> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "guidclientenapis": localStorage.getItem("guidclientenapis"),
      "segment": localStorage.getItem("segment")
    };

    return this.http.post<ConfiguracaoBanner[]>(this.baseUrl + "api/Configuracao/GetConfiguracaoBannerByGuidClienteNapis", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of([]);
      })
    );
  }
}
