import { Injectable, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

/* Services */

import { CallCenterService } from '../call-center/call-center.service';
import { ClienteService } from '../clientes/cliente.service';
import { ConfiguracaoService } from '../configuracoes/configuracao.service';
import { ControleAcessoService } from '../controle-acessos/controle-acesso.service';
import { VendedorService } from '../vendedores/vendedor.service';

/* Models */

import { CallCenterConfiguracao } from '../../models/call-center/call-center-configuracao';
import { Cliente } from '../../models/clientes/cliente';
import { ClienteEmpresa } from '../../models/clientes/cliente-empresa';
import { Configuracao } from '../../models/configuracoes/configuracao';
import { ControleAcessoCliente } from '../../models/controle-acessos/controle-acesso-cliente';
import { Vendedor } from '../../models/vendedores/vendedor';

@Injectable({
  providedIn: 'root'
})

export class AuthService {
  /* Call Center */

  private callCenterConfiguracao: CallCenterConfiguracao = null;

  /* Clientes */

  private cliente: Cliente = null;

  /* Configurações */

  configuracao: Configuracao = null;

  /* Vendedores */

  private vendedor: Vendedor = null;

  exibirNavBarEmitter = new EventEmitter<boolean>();

  constructor(
    private router: Router,
    private toastr: ToastrService,
    private callCenterService: CallCenterService,
    private clienteService: ClienteService,
    private configuracaoService: ConfiguracaoService,
    private controleAcessoService: ControleAcessoService,
    private vendedorService: VendedorService
  ) { }

  autorizar(documento: string, guidclientenapis: string, senha: string, clienteEmpresaSelecionado: ClienteEmpresa) {
    this.clienteService.getByLogin(documento, guidclientenapis, senha).subscribe(
      cliente => this.cliente = cliente,
      error => { console.log("Erro: " + error) },
      () => {
        if (this.cliente == null) {
          this.toastr.error("", "Usuário inválido!");

          this.exibirNavBarEmitter.emit(false);
        } else {
          /* Cliente (Endereço Completo) */

          this.cliente.name = this.cliente.name.trim();
          this.cliente.endereco_completo = "";

          if (this.cliente.tipo_logradouro != null) {
            this.cliente.endereco_completo = this.cliente.endereco_completo + this.cliente.tipo_logradouro.trim();
          }

          if (this.cliente.endereco != null) {
            this.cliente.endereco_completo = this.cliente.endereco_completo + " " + this.cliente.endereco.trim();
          }

          if (this.cliente.end_numero != null) {
            this.cliente.endereco_completo = this.cliente.endereco_completo + ", " + this.cliente.end_numero.trim();
          }

          if (this.cliente.cidade != null) {
            this.cliente.endereco_completo = this.cliente.endereco_completo + " / " + this.cliente.cidade.trim();
          }

          this.cliente.endereco_completo = this.cliente.endereco_completo.trim().toUpperCase();

          /* Cliente (Empresa) */

          localStorage.setItem("nm_empresa", clienteEmpresaSelecionado.nm_empresa.toString());

          if (clienteEmpresaSelecionado.empresa_fone != null) {
            localStorage.setItem("empresa_fone", clienteEmpresaSelecionado.empresa_fone.toString());
          }

          if (clienteEmpresaSelecionado.empresa_endereco != null) {
            localStorage.setItem("empresa_endereco_bairro", clienteEmpresaSelecionado.empresa_endereco.toString());

            if (clienteEmpresaSelecionado.empresa_bairro != null) {
              let empresa_endereco_bairro = localStorage.getItem("empresa_endereco_bairro") + " - " + clienteEmpresaSelecionado.empresa_bairro;

              localStorage.setItem("empresa_endereco_bairro", empresa_endereco_bairro.toString());
            }
          }

          if (clienteEmpresaSelecionado.empresa_cidade_estado != null) {
            localStorage.setItem("empresa_cidade_estado", clienteEmpresaSelecionado.empresa_cidade_estado.toString());
          }

          if (clienteEmpresaSelecionado.empresa_email != null) {
            localStorage.setItem("empresa_email", clienteEmpresaSelecionado.empresa_email.toString());
          }

          /* Cliente */

          localStorage.setItem("client_id_app", this.cliente.client_id_app.toString());
          localStorage.setItem("guidclientenapis", this.cliente.guidclientenapis.toString());
          localStorage.setItem("name", this.cliente.name.toString());
          localStorage.setItem("endereco_completo", this.cliente.endereco_completo.toString());
          localStorage.setItem("seller_id", this.cliente.seller_id.toString());

          if (this.cliente.segment == null) {
            localStorage.setItem("segment", "");
          } else {
            localStorage.setItem("segment", this.cliente.segment.toString());
          }

          localStorage.setItem("client_document", this.cliente.client_document.toString());

          if (this.cliente.b2b_margem_consumidor == null) {
            localStorage.setItem("b2b_margem_consumidor", "0");
          } else {
            localStorage.setItem("b2b_margem_consumidor", this.cliente.b2b_margem_consumidor.toString());
          }

          /* Configurações */

          this.configuracaoService.getByGuidClienteNapis().subscribe(
            configuracoes => this.configuracao = configuracoes,
            error => { console.log("Erro: " + error) },
            () => {
              if (this.configuracao != null) {
                if (this.configuracao.b2b_usa_como_orcamento == null) {
                  localStorage.setItem("b2b_usa_como_orcamento", "false");
                } else {
                  localStorage.setItem("b2b_usa_como_orcamento", this.configuracao.b2b_usa_como_orcamento.toString());
                }

                if (this.configuracao.mercadopago_public_token == null) {
                  localStorage.setItem("mercadopago_public_token", "");
                } else {
                  localStorage.setItem("mercadopago_public_token", this.configuracao.mercadopago_public_token.toString());
                }

                if (this.configuracao.b2b_cor_primaria == null) {
                  localStorage.setItem("b2b_cor_primaria", "#0c4976");
                } else {
                  localStorage.setItem("b2b_cor_primaria", this.configuracao.b2b_cor_primaria);
                }

                /* Call Center (Configuração) */

                this.callCenterService.getConfiguracaoByGuidClienteNapis().subscribe(
                  callCenterConfiguracoes => this.callCenterConfiguracao = callCenterConfiguracoes,
                  error => { console.log("Erro: " + error) },
                  () => {
                    if (this.callCenterConfiguracao != null) {
                      localStorage.setItem("logomarca", this.callCenterConfiguracao.logotipo.toString());

                      /* Controle Acesso */

                      let controleAcessoCliente = new ControleAcessoCliente();

                      controleAcessoCliente.idclient = this.cliente.id;

                      let adicionarControleAcesso: number = 0;

                      this.controleAcessoService.addControleAcessoCliente(controleAcessoCliente).subscribe(
                        adicionarControleAcessos => adicionarControleAcesso = adicionarControleAcessos,
                        error => { console.log("Erro: " + error) },
                        () => {

                        }
                      );

                      /* Vendedor */

                      this.vendedorService.getById().subscribe(
                        vendedor => this.vendedor = vendedor,
                        error => { console.log("Erro: " + error) },
                        () => {
                          /* Data/Hora */

                          let dataHora: Date = new Date();

                          dataHora.setHours(23);
                          dataHora.setMinutes(59);
                          dataHora.setSeconds(59);

                          localStorage.setItem("data_hora", dataHora.toString());

                          if (this.vendedor != null) {
                            if (this.vendedor.link_whatsapp == null) {
                              localStorage.setItem("link_whatsapp", "");
                            } else {
                              localStorage.setItem("link_whatsapp", this.vendedor.link_whatsapp.toString());
                            }
                          }

                          if (this.cliente.resetar_senha == null || this.cliente.resetar_senha == true) {
                            this.router.navigate(["/redefinir-senha"]);
                          } else {
                            this.router.navigate(["/produtos"]);

                            this.exibirNavBarEmitter.emit(true);
                          }
                        }
                      );
                    } else {
                      this.toastr.error("", "Nenhuma configuração encontrada!");

                      this.desautorizar();
                    }
                  }
                );
              } else {
                this.toastr.error("", "Nenhuma configuração encontrada!");

                this.desautorizar();
              }
            }
          );
        }
      }
    );
  }

  desautorizar() {
    this.exibirNavBarEmitter.emit(false);

    localStorage.clear();

    this.router.navigate(["/login"]);
  }

  statusAutorizacao() {
    if (localStorage.getItem("data_hora") != "") {
      let dataHoraAtual: Date = new Date();
      let dataHoraExpiracao: Date = new Date(localStorage.getItem("data_hora"));

      if (dataHoraAtual < dataHoraExpiracao) {
        if (localStorage.getItem("guidclientenapis") != "") {
          this.exibirNavBarEmitter.emit(true);

          return true;
        }
      } else {
        if (this.router.isActive("", false) || this.router.isActive("login", false)) {
          /* Storage */

          /* Data/Hora */

          let dataHora: Date = new Date();

          dataHora.setHours(23);
          dataHora.setMinutes(59);
          dataHora.setSeconds(59);

          localStorage.setItem("data_hora", dataHora.toString());
        }
      }
    }

    return false;
  }
}
